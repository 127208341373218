.to-right-gradient-bg{
  background-image: linear-gradient(to right, transparent, var(--gradient-color));
}

.content-container{
  /* background-image: linear-gradient(var(--bg-color) 75%, var(--gradient-color) 100%); */
  position: relative; /*so that Polygon will not cover contents*/
  /* z-index: -1; */
}

.display-none{
  display: none;
}

.text-center-align{
  text-align: center;
}

.text-right-align{
  text-align: right;
}

.cursor-pointer{
  cursor: pointer;
}

.dark-blue-bg{
  background-color: var(--dark-blue-color);
}

/* small devices */
@media (max-width: 767.98px) {
  .mobile {
    display: block;
    background-image: linear-gradient(var(--bg-color), var(--gradient-color));
  }

  .mobile-content{
    display: block;
  }

  .pc {
    display: none;
  }
}

/* over medium devices */
@media (min-width: 768px) {
  .mobile, .mobile-content {
    display: none;
  }

  .pc {
    display: block;
  }

  .to-right-gradient-bg{
    background-image: linear-gradient(to right, transparent, var(--gradient-color));
  }
}

.yellow-color{
  color: var(--yellow-color);
}

.orange-color{
  color: var(--orange-color);
}

.gradient-bg{
  background-image: linear-gradient(var(--bg-color) 0%, var(--gradient-color) 100%);
}

.content-offset{
  background-color: var(--bg-color);
  height: 100px;
}

.content-s-offset{
  background-color: var(--bg-color);
  height: 50px;
}

.content-card-padding{
  padding-top: 2.5em !important;
  padding-left: 5em !important;
  padding-bottom: 2.5em !important;
}

.large-font{
  font-size: 20px;
}

.inline-flex{
  display: inline-flex;
}

.bold-font{
  font-weight: bold;
}

.underline{
  text-decoration-line: underline;
}

.sg-invalid-feedback{
  width: 100%;
  margin-top: 0.25rem;
  color: red;
}

.sg-valid-feedback{
  width: 100%;
  margin-top: 0.25rem;
  color: limegreen;
}

.right-align{
  display: flex;
  justify-content: flex-end;
}

.center-align{
  display: flex;
  justify-content: center;
}

.sg-link{
  color: var(--yellow-color);
  cursor: pointer;
}

.sg-link:hover{
  color: var(--yellow-color);
  text-decoration-line: underline;
}

.sg-pic{
  width: 100%;
  height: auto;
  border-radius: 1em;
}