.sg-level-div {
  display: inline-flex;
  align-items: center;
}

.sg-line {
  width: 6rem;
  height: 8px;
  background-color: var(--yellow-color);
}

.sg-arrow-div{
  width: 100%;

}

.arrow-right{
  border-top: 35px solid transparent;
  border-bottom: 35px solid transparent;
  border-left: 35px solid var(--yellow-color);
}