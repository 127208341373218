.sg-polygon{
    height: 37em;
    background-color: var(--dark-blue-color);
    transform: rotate(180deg);
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%); 
}

.sg-polygon-parent{
    width: 100%;
    position: absolute;
    filter: drop-shadow(5px 20px 28px black);
    border: 10px;
    top: 0px;
    z-index: 1;
}

@media (max-width: 1200px){
    .projects-polygon-pc{
        display: none;
    }

    .sg-polygon-parent{
        display: none;
    }
}