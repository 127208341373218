.sg-carousel-left-arrow{
    position: absolute;
    bottom: 35%;
    left: 0;
    /* margin-left: 20px; */
}

.sg-carousel-right-arrow{
    position: absolute;
    bottom: 35%;
    right: 0;
    /* margin-right: 20px; */
}