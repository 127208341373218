.pc-comp-offset {
  height: 150px;
}

.pc-comp-bottom-offset {
  height: 200px;
}

.pc-content-col {
  /* background-image: linear-gradient(
    var(--bg-color) 0%,
    var(--gradient-color) 100%
  ); */
}

@media (max-width: 1200px){
  .linear-col{
    display: none;
  }
}