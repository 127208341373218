.sg-icon {
    width: 3em;
    object-fit: contain;
    cursor: pointer;
  }

@media (max-width: 767.98px){
  .sg-icon{
    width: 3em;
  }
}