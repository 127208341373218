.sg-footer{
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: black;
}

.sg-footer-right-border{
    height: 100%;
    border-style: hidden solid hidden hidden;
    border-color: var(--orange-color);
}

.sg-footer-col{
    height: 15em;
    padding: 20px !important;
}

.sg-footer-content-header{
    font-size: 20px;
    color: var(--yellow-color);
}

.sg-footer-content{
    color: var(--yellow-color);
}

.sg-footer-content:hover{
    color: var(--orange-color);
    text-decoration-line: none;
}

.sg-footer-first-col{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px
}

.copyright-mb{
    padding-bottom: 20px;
}

@media (max-width: 767.98px){
    .sg-footer-mobile{
        display: flex; 
        justify-content: center;
        padding-bottom: 20px;
    }
    
    .sg-footer-mobile-header{
        display: flex; 
        justify-content: center;
        padding-top: 20px;
    }

    .copyright-mb-mobile{
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}