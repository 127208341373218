.sg-modal-content-carousel{
    display: inline;
}

.sg-modal-carousel-img{
    width: 100%;
    object-fit: contain;
    max-height: 80vh;
    background-color: black;
}

.sg-modal-carousel-left-arrow{
    position: absolute;
    z-index: 1;
    bottom: 45%;
}

.sg-modal-carousel-right-arrow{
    z-index: 1;
    position: absolute;
    bottom: 45%;
    right: 0;
}

@media (max-width: 767.99px){
    .sg-modal-carousel-left-arrow{
        bottom: 40%;
        left: 0;
    }

    .sg-modal-carousel-right-arrow{
        bottom: 40%;
    }

    .sg-modal-carousel-img{
        max-height: 40vh;
    }
}