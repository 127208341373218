.sg-logo1{
    width: 25%;
    padding: 10px;
    box-sizing: unset;
}

.sg-logo2{
    width: 50%;
    padding: 10px;
    box-sizing: unset;
}

.sg-logo-col{
    background-color: var(--light-yellow-color);
    z-index: 1;
}

@media (max-width: 767.99){

}