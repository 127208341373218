.about-team-pic{
    width: 200px;
    height: 200px;
    border-radius: 1em;
}

.team-row{
    padding-bottom: 4em;
}

/* pc */
@media (min-width: 950px){
    .about-pc{
        display: block;
    }

    .about-mobile{
        display: none;
    }
}

/* mobile */
@media (max-width: 949.98px){
    .about-pc{
        display: none;
    }

    .about-mobile{
        display: block;
    }

    .about-mobile-padding{
        padding: 20px;
    }
}