
/* mobile */
@media (max-width: 767.98px) {
    .hamburger{
        color: var(--yellow-color);
        border: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .nav-btn-mobile-col{
        display: flex;
        justify-content: center;
        background-color: var(--nav-btn-color);
        cursor: pointer;
        align-items: center;
    }

    .nav-btn-padding-top{
        padding-top: 10px;
    }

    .nav-btn-padding-bottom{
        padding-bottom: 10px;
    }
}