/* pc */
@media (min-width: 1200px) {
  .home-offset{
    background-color: var(--bg-color);
    height: 50px;
  }

  /* home-offset + game-bg height*/
  .announcement-offset{ 
    background-color: var(--bg-color);
    height: 450px;
  }

  .home-pc-sg-card {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .home-pic-card{
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .first-row-pb{
    padding-bottom: 3rem;
  }

  .presskit-col{
    flex: 1 1 99%;
    max-width: 90%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .input-container{
    padding-bottom: 2rem !important;
  }

  .sub-input-col{
    padding-right: 20px !important;
  }
}

/* medium mobile*/
@media (min-width: 768px){
  .input-container{
    padding-bottom: 3rem;
  }

  .first-row-pb{
    padding-bottom: 3rem;
  }

  .presskit-col{
    flex: 1 1 80%;
    max-width: 90%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .sub-input-col{
    padding-right: 30px;
  }
}

.newsletter-container{
  padding-bottom: 4em;
}

.game-bg{
  background-image: url("../../assets/images/game-bg2.jpeg");
  background-size: cover;
  background-repeat: round;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .game-bg{
      background-image: url("../../assets/images/game-bg2.jpeg");
      background-size: cover;
      background-repeat: round;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 1;
      height: 20%;
    }
  }
}

.game-logo{
  padding: 30px;
  width: 60%;
  height: auto;
}

.home-new-announcement-title{
  color: var(--yellow-color);
}

.home-tsd-pic{
  width: 100%;
  height: auto;
  border-radius: 1em;
}

.form-control-width{
  width: 70%;
}

/* mobile */
@media (max-width: 767.98px){
  .game-logo{
    width: 100%;
    padding: 20px;
  }
  
  .home-game-content{
    text-align: center;
    padding-bottom: 20px;
  }

  .home-game-title{
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .home-search-icon{
    width: 20%;
  }

  .input-row{
    padding-left: 15px;
    padding-right: 15px;
  }

  /* .game-bg{
    height: 200px;
  } */
}