.switch-lang-btn{
    background-color: var(--yellow-color);
    border-radius: 5px;
    border: 0;
    height: 100%;
}

.switch-lang-btn:focus, .switch-lang-dropdown-item:focus{
    outline: none;
}

.switch-lang-dropdown-menu{
    background-color: var(--yellow-color);
}

.switch-lang-dropdown-item:hover, .switch-lang-dropdown-item:active{
    background-color: var(--orange-color);
    color: black;
}