.sg-pic-card-image{
    border-radius: 12%;
    padding: 20px;
}

.sg-pic-card-title{
    color: var(--yellow-color);
}

.sg-pic-card-search-icon{
    position: absolute;
    width: 60px;
    bottom: 15px;
    right: 0;
}

@media (max-width: 767.98px){
    .sg-pic-card-search-icon{
        right: 15px;
    }
}

.sg-pic-card-image-div{
    cursor: pointer;
}