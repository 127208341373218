.sg-title{
    display: inline-flex;
}

.sg-title-h1{
    color: var(--yellow-orange-color);
}

.arrow-style{
    align-self: center;
}