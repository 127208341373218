.modal, .modal p, h1{
    color: black;
}

.close:focus{
    outline: none;
}

.sg-modal-content{
    background-color: rgb(254,223,138);
    margin-top: 10px;
    border: 25px solid var(--yellow-color);
    border-radius: 15px;
}

.modal-body-carousel{
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.close-icon{
    cursor: pointer;
    position: absolute;
    color: white;
    top: -40px;
    left: -40px;
}

.close-icon-carousel{
    cursor: pointer;
    position: absolute;
    color: white;
    top: -20px;
    left: -20px;
    z-index: 1;
}

.modal-dialog-carousel{
    /* max-height: 400px; */
    max-width: 800px;
}