.linear-gradient-svg{
    height: 100%;
    background-image: linear-gradient(transparent 0%, var(--gradient-color) 100%);
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
}

/* .sg-polygon{
    height: 100vh;
    background-color: var(--dark-blue-color);
    transform: rotate(180deg);
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%); 
}

.sg-polygon-parent{
    width: 100%;
    position: absolute;
    filter: drop-shadow(5px 20px 28px black);
    border: 10px;
    top: 100px;
} */