
/* pc */
@media (min-width: 768px){
    .a-padding{
        padding-top: 2.5em;
        padding-left: 5em;
    }

    .tsd-pic-col{
        align-self: center;
    }

    .dev-blog-offset{
        padding-bottom: 1em;
    }

    .projects-tablet{
        display: none;
    }
}

/* mobile */
@media (max-width: 1015px){
    .projects-pc{
        display: none;
    }

    .projects-tablet{
        display: block;
    }
}

.sg-level-h5{
    background-color: var(--orange-color);
}

.tsd-pic{
    width: 100%;
    height: auto;
}