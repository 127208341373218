/* small devices */
@media (max-width: 767.98px) {
  .carousel-pic {
    border-radius: 10%;
    width: 100%;
  }

  .title {
    color: var(--yellow-color);
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
  }

  .text-col {
    text-align: center;
    margin-top: 1rem;
  }

  .arrow {
    align-self: center;
    opacity: 0.7;
  }

  .project-div {
    display: flex;
    justify-content: center;
  }

  .content{
    margin-left: 20px;
    margin-right: 20px;
  }

  .more-info{
    margin-bottom: 30px;
  }
}

/* over medium devices */
@media (min-width: 768px) {

  .carousel-pic {
    width: 500px;
    height: auto;
    border-radius: 10%;
  }

  .title {
    color: var(--yellow-color);
    text-align: right;
    margin-right: 3rem;
  }

  .pic-col {
    display: flex;
    justify-content: center;
  }

  .more-info{
    float: right;
    margin-bottom: 30px;
  }
}

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  width: 14px;
  height: 15px;
  background-color: var(--yellow-color);
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 50px;
}

ol.carousel-indicators li {
  margin-bottom: 10px;
  background-color: white;
  opacity: 1;
}
