/* pc */
@media (min-width: 1051px){
    .donate-pc{
        display: block;
    }

    .donate-tablet{
        display: none;
    }

    .donate-mobile{
        display: none;
    }

    .donate-content-offset{
        height: 150px;
    }
    
    .support-second-col{
        align-self: center;
        display: flex;
    }
    
    .support-img{
        width: 100%;
        height: auto;
    }
    
    .first-col-offset{
        height: 20px;
    }

    .donate-bottom-offset{
        height: 100px;
    }
}

/* tablet */
@media (max-width: 1050.99px) {
    .donate-pc{
        display: none;
    }

    .donate-tablet{
        display: block;
    }

    .donate-mobile{
        display: none;
    }

    .support-img{
        width: 30%;
        height: auto;
    }

    .support-img-tablet-div{
        /* display: flex; */
        justify-content: center;
    }
}



/* mobile */
@media (max-width: 767.98px){
    .support-img{
        width: 60%;
        height: 30%;
    }
}