.sg-pic-card-btn{
    background-color: rgb(255,255,255,.1);
    color: var(--orange-color);
    border: 0;
    border-width: 10px;
    border-radius: 0.35em;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px; 
    font-weight: bold;
    margin-right: 20px;
}

.sg-pic-card-btn-span{
    margin-right: 40px;
}

/* small devices */
@media (max-width: 767.99px){
    .sg-pic-card-btn-span{
        margin-bottom: 10px;
    }

    .sg-pic-card-btn-pc{
        display: none;
    }

    .sg-pic-card-btn-span-mobile{
        display: flex;
        justify-content: center;
        margin-right: 0;
    }

    .sg-pic-card-btn-icon-mobile{
        display: flex;
        justify-content: center;
    }

    .to-right-gradient-bg{
      background-image: linear-gradient(var(--bg-color), var(--gradient-color));
    }
}

@media (min-width: 768px){
    .sg-pic-card-btn-mobile{
        display: none;
    }
}

.sg-pic-card-btn-icon{
    width: auto;
    height: 25px;
    margin-right: 10px;
}