.nav-btn{
    font-weight: bold;
    background-color: var(--nav-btn-color);
    border: 0;
    color: var(--yellow-color);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-btn:hover {
    color: var(--yellow-color);
    text-decoration-line: none;
}

/* pc */
@media (min-width: 768px) {
    .nav-btn{
        /* col css */
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        z-index: 1;
    }

    .nav-btn-text{
        font-size: 20px;
    }
}

/* mobile */
@media (max-width: 767.99px){
    .nav-btn{
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .nav-btn-text{
        font-size: 15px;
    }
}