.sg-motto{
    background-color: var(--dark-blue-color);
    position: relative;
    z-index: 3;
    padding: 20px;
}

.sg-motto-text{
    font-size: 20px;
}

.sg-motto-lang{
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

@media (max-width: 767.99px){
    .sg-motto-text{
        font-size: 12px;
    }
}