.sg-pic-modal-carousel-search{
    position: absolute;
    right: 0;
    bottom: 0;
}

.sg-pic-modal-carousel-div{
    width: 100%;
}

.sg-pic-modal-carousel{
    width: 100%;
    height: auto;
}

@media (min-width: 767.98px) and (max-width: 800px){
    .sg-pic-modal-carousel{
        width: 60%;
    }
}