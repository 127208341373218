.sg-cover{
    width: 100%;
    /* height: 150px; */
    position: relative;
    z-index: 2;
}

@media (max-width: 767.99px){
    .sg-cover{
        /* height: 100px; */
    }
}