@font-face {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/Sarabun/Sarabun-Regular.ttf);
}

@font-face{
  font-family: "Prompt";
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/Prompt/Prompt-Regular.ttf);
}

.thai-font{
  font-family: 'Prompt';
}

.english-font{
  font-family: 'Sarabun';
}

body, html, #root {
  --yellow-color: rgb(247, 211, 112);
  --light-yellow-color: rgb(251,228,164);
  --yellow-orange-color: rgb(238, 168, 67);
  --orange-color: rgb(246, 148, 26);
  --bg-color: rgb(24,60,88);
  --gradient-color: rgb(3,14,33);
  --nav-btn-color: rgba(0,0,0, 0.5);
  --dark-blue-color: rgb(16,29,47);

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: linear-gradient(var(--bg-color) 75%, var(--gradient-color) 100%); */
  background-color: var(--bg-color);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  color: white;
}

h1,
h2,
h3,
h4,
h5{
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
